import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReporterItem from '../../components/AiHuman/ReporterItem';
import Button from '../../components/common/Button/Button';
import Divider from '../../components/common/Divider/Divider';
import TextField from '../../components/common/TextField/TextField';
import { getAiHumanList, deleteAiHuman } from '../../features/human/humanSlice';
import { AppDispatch, RootState } from '../../store';
import css from './AIHumanPanel.module.scss'
import Popup from '../../components/common/Popup/Popup';

const LabeledComponent = ({
    children,
    label
}: {
    children: JSX.Element;
    label: string;
}) => {
    return (
        <div className={css.labeledComponent}>
            <div className={css.label}>{label}</div>
            <div className={css.comp}>
                {children}
            </div>
        </div>
    );
}

const AIHumanPanel = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { reporterList } = useSelector((state: RootState) => state.human);
    const [selected, setSelected] = useState<any>();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleDeleteApply = () => {
        if (!selected.id) {
            return;
        }
        setShowDeleteConfirm(true);
    }

    const handleConfirmDelete = async () => {
        await deleteAiHuman(selected.id).then((res) => {
            // 삭제 성공 시
        }).catch((err) => {
            // 삭제 실패 시
            console.log(err);
        }).finally(() => {
            setShowDeleteConfirm(false);
            dispatch(getAiHumanList());
        });
    }

    useEffect(() => {
        dispatch(getAiHumanList());
    }, [dispatch]);

    useEffect(() => {
        if (reporterList.length > 0) {
            setSelected(reporterList[0]);
        }
    }, [reporterList]);

    return (
        <div className={css.aiHumanPanel}>
            <h2>AI 휴먼 관리</h2>

            <div className={css.selected}>
                <div className={css.selectedContainer}>
                    <div className={css.selectedWrapper}>
                        <LabeledComponent label="이름">
                            <TextField onChange={null} readonly value={selected?.name} />
                        </LabeledComponent>
                        <LabeledComponent label="보도국">
                            <TextField onChange={null} readonly value={selected?.broadcast}/>
                        </LabeledComponent>
                        <LabeledComponent label="">
                            <Button priority="secondary" type="line" inline  className={css.button} disabled={!selected?.name || !selected?.broadcast} onClick={handleDeleteApply}>{'기자 정보 삭제'}</Button>
                        </LabeledComponent>
                    </div>
                </div>
                <Divider />
            </div>
            <div className={css.reporterList}>
                <div className={css.list}>
                    {
                        reporterList.map((item, index) => {
                            return (
                                <ReporterItem
                                    key={item.id}
                                    src={item.src}
                                    title={item.name}
                                    thumbnail={item.profileUrl}
                                    selected={selected?.id === item.id}
                                    onClick={() => setSelected(item)}
                                />
                            );
                        })
                    }
                </div>
            </div>

            <Popup
                open={showDeleteConfirm}
                content={`정말로 ${selected?.name} 기자를 삭제하시겠습니까?`}
                onClose={() => setShowDeleteConfirm(false)}
                buttons={
                    <>
                        <Button
                            onClick={handleConfirmDelete}
                            priority="primary"
                        >
                            삭제
                        </Button>
                        <Button
                            onClick={() => setShowDeleteConfirm(false)}
                            priority="secondary"
                        >
                            취소
                        </Button>
                    </>
                }
            />
        </div>
    );
};

AIHumanPanel.displayName = 'AIHumanPanel';

export default AIHumanPanel;
