import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { ConfigProvider, theme, Table, Upload, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import css from './MusicPanel.module.scss';
import { BgmRecordType, addBgm, deleteBgm, getBgmList } from '../../features/music/musicSlice';
import Button from '../../components/common/Button/Button';
import Divider from '../../components/common/Divider/Divider';
import Popup from '../../components/common/Popup/Popup';
import TextField from '../../components/common/TextField/TextField';

const LabeledComponent = ({
    children,
    label
}: {
    children: JSX.Element;
    label: string;
}) => {
    return (
        <div className={css.labeledComponent}>
            <div className={css.label}>{label}</div>
            <div className={css.comp}>
                {children}
            </div>
        </div>
    );
}

const MusicPanel = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { bgmList } = useSelector((state: RootState) => state.music);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [bgmToDelete, setBgmToDelete] = useState<BgmRecordType>();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [uploadName, setUploadName] = useState('');
    const [uploadFile, setUploadFile] = useState<File>();
    const [fileList, setFileList] = useState<any[]>([]);

    useEffect(() => {
        dispatch(getBgmList());
    }, [dispatch]);

    const columns: ColumnsType<BgmRecordType> = [
        {
            title: '아이디',
            dataIndex: 'id',
            key: 'id',
            width: 250,
            hidden: true,
        },
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '배경 음악',
            dataIndex: 'src',
            key: 'src',
            render: (_, record) => (
                <audio style={{ height: '1.7rem', width: '35rem' }} src={record.src} controls />
            ),
        },
        {
            title: '액션',
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Button priority="secondary" type="line" inline onClick={() => handleDelete(record)}>
                    삭제
                </Button>
            ),
        }
    ];

    const handleAdd = () => {
        setUploadModalOpen(true);
    };

    const handleUploadApply = async () => {
        if (!uploadName || !uploadFile) {
            return;
        }
        await addBgm(uploadName, uploadFile).then(() => {
            // 업로드 성공 시

        }).catch((err) => {
            // 업로드 실패 시
            console.log(err);
        }).finally(() => {
            setUploadModalOpen(false);
            setUploadName('');
            setUploadFile(undefined);
            setFileList([]);
            // 다시 불러오기
            dispatch(getBgmList());
        });
    };

    const handleDelete = (bgm: BgmRecordType) => {
        setBgmToDelete(bgm);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!bgmToDelete?.id) {
            return;
        }
        await deleteBgm(bgmToDelete.id).then(() => {
            // 삭제 성공 시
            setDeleteConfirmOpen(false);
        }).catch((err) => {
            // 삭제 실패 시
            console.log(err);
        }).finally(() => {
            setDeleteConfirmOpen(false);
            // 다시 불러오기
            dispatch(getBgmList());
        });
    };

    const beforeUpload = (file: File) => {
        const isAudio = file.type.startsWith('audio/');
        if (!isAudio) {
            message.error('오디오 파일만 업로드할 수 있습니다!');
            return false;
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('파일은 10MB보다 작아야 합니다!');
            return false;
        }
        setUploadFile(file);
        return false; // Return false to prevent auto upload
    };

    return (
        <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            <div className={css.musicPanel}>
                <h2>배경 음악 관리</h2>

                <div className={css.header}>
                    <div className={css.top}>
                        <div className={css.right}>
                            <Button priority="secondary" type="line" inline onClick={handleAdd}>
                                {'음악 추가'}
                            </Button>
                        </div>
                    </div>
                </div>
                <Divider />
                <Table
                    columns={columns}
                    dataSource={bgmList}
                    rowKey="id"
                    pagination={{
                        total: bgmList.length
                    }}
                />

                {/* 업로드 팝업 */}
                <Popup
                    open={uploadModalOpen}
                    onClose={() => setUploadModalOpen(false)}
                    content="배경음악 추가"
                    contentSlot={
                        <div className={css.uploadForm}>
                            <div className={css.formWrapper}>
                                <LabeledComponent label="음악 이름">
                                    <TextField
                                        type="text"
                                        placeholder="음악 이름"
                                        value={uploadName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUploadName(e.target.value)}
                                    />
                                </LabeledComponent>
                                <LabeledComponent label="파일">
                                    <Upload
                                        accept="audio/*"
                                        beforeUpload={beforeUpload}
                                        fileList={fileList}
                                        onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                                        maxCount={1}
                                        showUploadList={{
                                            showPreviewIcon: true,
                                            showRemoveIcon: true,
                                            showDownloadIcon: false
                                        }}
                                        listType="text"
                                    >
                                        <Button type="line" priority="secondary">
                                            파일 선택
                                        </Button>
                                    </Upload>
                                </LabeledComponent>
                            </div>
                        </div>
                    }
                    buttons={
                        <>
                            <Button priority="secondary" type="line" onClick={() => {setUploadModalOpen(false); setUploadName(''); setUploadFile(undefined);}}>
                                취소
                            </Button>
                            <Button priority="primary" onClick={handleUploadApply}>
                                추가
                            </Button>
                        </>
                    }
                />

                {/* 삭제 확인 팝업 */}
                <Popup
                    open={deleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                    content="정말로 이 음악을 삭제하시겠습니까?"
                    buttons={
                        <>
                            <Button priority="secondary" type="line" onClick={() => {setDeleteConfirmOpen(false); setBgmToDelete(undefined);}}>
                                취소
                            </Button>
                            <Button priority="primary" onClick={handleDeleteConfirm}>
                                삭제
                            </Button>
                        </>
                    }
                />
            </div>
        </ConfigProvider>
    );
}

export default MusicPanel;
