import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET, POST } from '../../utils/api';


export interface TemplateRecordType {
    id: string;
    title: string;
    brand: string;
    closingComment: string;
}

interface TemplateListType {
    templateList: TemplateRecordType[];
}

// Define async thunks
export const getTemplateList = createAsyncThunk('/admin/get-template-list', async () => {
    const res = await GET('admin/get-template-list');
    if (!res.errMsg && Array.isArray(res.templateList) && res.templateList) {
        return res.templateList;
    } else {
        return [];
    }
});


export const addTemplate = async ({title, brand, closingComment}: Omit<TemplateRecordType, 'id'>) => {
    const body = {
        title: title,
        brand: brand,
        closingComment: closingComment
    };
    return await POST('/admin/add-template', body, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};


export const editTemplate = async ({id, title, brand, closingComment}: TemplateRecordType) => {
    const body = {
        id: id,
        title: title,
        brand: brand,
        closingComment: closingComment
    };
    return await POST('/admin/edit-template', body, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const deleteTemplate = async (id: string) => {
    const body = {
        id: id
    };
    return await POST('/admin/delete-template', body);
};


const initialState: TemplateListType = {
    templateList: []
};

const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setTemplateList(state, action: PayloadAction<TemplateRecordType[]>) {
            state.templateList = action.payload;
        },
        reloadTemplateList(state) {
            getTemplateList();
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTemplateList.fulfilled, (state, action) => {
            console.log('getTemplateList', action.payload);
            state.templateList = action.payload;
        });
    }
});

export const { setTemplateList } = templateSlice.actions;
export default templateSlice.reducer;
