import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Swal from 'sweetalert2';
// import 'sweetalert2/src/sweetalert2.scss';
import './styles/sweetalert2-dark.scss';
import './App.scss';
import Login from './views/Login/Login';
import Layout from './components/common/Layout/Layout';
import AIHumanPanel from './views/AIHuman/AIHumanPanel';
import ActivityPanel from './views/Activities/Activities';
import TemplatePanel from './views/Template/TemplatePanel';
import MusicPanel from './views/Music/MusicPanel';
import ImagePanel from './views/Image/ImagePanel';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession, signOut, getCurrentUser } from 'aws-amplify/auth';
import config from './amplifyconfiguration.json';
import { useEffect } from 'react';
import NotFound from './views/common/NotFound';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './store';
import { setUserId } from './features/user/userSlice';
Amplify.configure(config);


const App = () => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const user = await getCurrentUser(); // 로그인 상태 확인
                const { userId } = user;

                // Get the current session to access the JWT token
                const session = await fetchAuthSession();
                const groups = session.tokens?.accessToken?.payload['cognito:groups'];
                console.log('Current user groups:', user, groups);

                if (Array.isArray(groups) && groups.includes('admin')) {
                    dispatch(setUserId(userId));
                } else {
                    const result = await Swal.fire({
                        title: 'Access Denied',
                        text: 'You do not have admin privileges. You will be signed out.',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK'
                    });

                    if (result.isConfirmed) {
                        await signOut();
                        window.location.href = `${window.location.origin}/login`;
                    }
                }

            } catch {
                console.log('Not logged in.');
                if (!window.location.pathname.includes('login')) {
                    window.location.href = `${window.location.origin}/login`;
                }
            }
        };

        checkAuthStatus();
    }, [dispatch]);

    const router = createBrowserRouter([
        {
            element: <Layout />,
            children: [
                {
                    path: "/",
                    element: <Navigate to={'/activities'} replace />
                },
                {
                    path: "/ai-human",
                    element: <AIHumanPanel />
                },
                {
                    path: "/news-template",
                    element: <TemplatePanel />
                },
                {
                    path: "/activities",
                    element: <ActivityPanel />
                },
                {
                    path: "/bg-music",
                    element: <MusicPanel />
                },
                {
                    path: "/bg-image",
                    element: <ImagePanel />
                },
                {
                    path: "/login",
                    element: <Login />
                },
                {
                    path: "*",
                    element: <NotFound />
                }
            ],
            errorElement: <NotFound />
        }
    ]);

    return (
        <RouterProvider router={router} />
    );
};

// export default withAuthenticator(App);
export default App;
