import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET } from '../../utils/api';

export interface ActivityRecord {
    id: string;
    name: string;
    date: string;
    activity: string;
}

interface ActivitiesState {
    activityList: ActivityRecord[];
}

export interface SearchParams {
    activity: string;
    from: string;
    to: string;
}

export const getActivityList = createAsyncThunk('/admin/get-activity-list', async ({activity, from, to}: SearchParams) => {
    const params = {
        activity: activity,
        startDate: from,
        endDate: to
    };
    console.log('GET /admin/get-activity-list', params);
    const res = await GET('/admin/get-activity-list', params);

    function formatDate(date: string) {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    if (!res.errMsg && Array.isArray(res.activityList) && res.activityList) {
        res.activityList = res.activityList.map((activity: ActivityRecord) => ({
            ...activity,
            date: formatDate(activity.date)
        }));
        return res.activityList;
    } else {
        return [];
    }
});

const initialState: ActivitiesState = {
    activityList: [],
};

const activitiesSlice = createSlice({
    name: 'activities',
    initialState,
    reducers: {
        //
    },
    extraReducers: (builder) => {
        // builder.addCase(getAiHumanList.fulfilled, (state, action) => {
        builder.addCase(getActivityList.fulfilled, (state, action) => {
            state.activityList = action.payload;
        });
    }
});

export default activitiesSlice.reducer;
