import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuType, setMenu } from '../../features/menu/menuSlice';
import { AppDispatch, RootState } from '../../store';
import css from './Menu.module.scss';
import MenuItem from './MenuItem';
import Logo from '../common/Logo/Logo';
import { signOut } from 'aws-amplify/auth';
import { useState, useEffect } from 'react';
import Popup from '../common/Popup/Popup';
import Button from '../common/Button/Button';

const Menu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = window.location.pathname;
    const dispatch = useDispatch<AppDispatch>();

    const { selected } = useSelector((state: RootState) => state.menu);
    const [openLogoutPopup, setLogoutPopup] = useState<boolean>(false);
    const isLoginPath = location.pathname.includes('login');

    const isValidState = (selected: string): selected is menuType['selected'] => {
        return ['ai-human', 'bg-image', 'bg-music', 'news-template', 'activities'].includes(selected);
    }

    useEffect(() => {
        const path = pathname.slice(1); // Remove leading slash
        if (path && isValidState(path)) {
            dispatch(setMenu(path));
        }
    }, [dispatch, pathname]); // Run once on mount

    const handleClickMenu = ({selected}: {selected: string}) => {
        if (!isValidState(selected)) {
            console.error('Invalid menu state.');
            return;
        }
        const path = pathname.slice(1); // Remove leading slash
        // check if the path is the same as the selected menu
        if (path !== selected) {
            dispatch(setMenu(selected));
            navigate(`/${selected}`);
        }
    };

    const reload = () => {
        window.location.href = window.location.origin;
    };

    const handleLogout = async () => {
        await signOut()
            .then(res => {
                window.location.reload();
            })
            .catch(err => {
                window.location.reload();
            })
    };

    return (
        <>
            {!isLoginPath ?
                <aside>
                    <div className={css.logoItem}>
                        <Logo direction="vertical" onClick={reload} />
                    </div>
                    {/* <MenuItem id="news-template" icon="newsTemplate" label="템플릿" selected={selected === 'news-template'} onClick={handleClickMenu} /> */}
                    <MenuItem id="bg-image" icon="image" label="이미지" selected={selected === 'bg-image'} onClick={handleClickMenu} />
                    <MenuItem id="bg-music" icon="music" label="음악" selected={selected === 'bg-music'} onClick={handleClickMenu} />
                    <MenuItem id="ai-human" icon="human" label="AI 휴먼" selected={selected === 'ai-human'} onClick={handleClickMenu} />
                    <MenuItem id="activities" icon="activities" label="관리로그" selected={selected === 'activities'} onClick={handleClickMenu} />
                    <MenuItem id="logout" icon="logout" label="로그아웃" onClick={() => setLogoutPopup(true)} className={css.logout} />
                </aside> : null
            }
            <Popup
                open={openLogoutPopup}
                content={'로그아웃 하시겠습니까?'}
                onClose={() => setLogoutPopup(false)}
                buttons={
                    <>
                        <Button type="line" onClick={() => setLogoutPopup(false)}>{'취소'}</Button>
                        <Button onClick={handleLogout}>{'확인'}</Button>
                    </>
                }
            />
        </>
    );
}

export default Menu;
