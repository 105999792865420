import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET, POST } from '../../utils/api';

export interface reporterType {
    name: string; // 이름
    id: string; // 아이디
    broadcast: string; // 방송사

    src: string; // 영상 주소
    profileUrl: string; // 프로필 이미지 주소
}

export interface humanDataType {
    selectedReporter: reporterType;
    reporterList: reporterType[];
}

export const getAiHumanList = createAsyncThunk('/admin/get-ai-human-list', async () => {
    const res = await GET('/admin/get-ai-human-list');
    if (!res.errMsg && Array.isArray(res.aiHumanList) && res.aiHumanList) {
        return res.aiHumanList;
    } else {
        return [];
    }
});

export const deleteAiHuman = async (id: string) => {
    const body = {
        id: id
    };
    return await POST('/admin/delete-ai-human', body);
};


export const initialState: humanDataType = {
    selectedReporter: {
        name: '',
        id: '',
        broadcast: '',
        src: '',
        profileUrl: ''
    },
    reporterList: []
};

export const humanSlice = createSlice({
    name: 'human',
    initialState,
    reducers: {
        //
    },
    extraReducers: (builder) => {
        builder.addCase(getAiHumanList.fulfilled, (state, action) => {
            state.reporterList = action.payload;
        });
    }
});

export default humanSlice.reducer;
