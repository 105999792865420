import React, { useState, useEffect, useCallback } from 'react';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { getActivityList, ActivityRecord } from '../../features/activities/activities';
import { ConfigProvider, theme, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store'; // Adjust the import based on your store structure

import Button from '../../components/common/Button/Button';
import Divider from '../../components/common/Divider/Divider';
import TextField from '../../components/common/TextField/TextField';
import FilterPopup from './FilterPopup';
import css from './Activities.module.scss';

const Activities: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { activityList } = useSelector((state: RootState) => state.activities);

    const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
    const [inputText, setInputText] = useState<string>('');
    const [activity, setActivity] = useState<string>('');
    const [createdDateTo, setCreatedDateTo] = useState<string>('');
    const [createdDateFrom, setCreatedDateFrom] = useState<string>('');

    const reset = () => {
        const today = new Date();
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        setActivity('');
        setCreatedDateTo(today.toISOString().split('T')[0]);
        setCreatedDateFrom(oneMonthAgo.toISOString().split('T')[0]);
    };

    // 기본 날짜 설정
    useEffect(() => {
        reset();
    }, []);

    const refreshList = useCallback(() => {
        dispatch(getActivityList({
            activity: activity,
            from: createdDateFrom,
            to: createdDateTo,
        }));
    }, [dispatch, activity, createdDateFrom, createdDateTo]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    const handleApplyFilter = useCallback((ev: {
        startDate: string;
        endDate: string;
    }) => {
        setCreatedDateFrom(ev.startDate);
        setCreatedDateTo(ev.endDate);
        refreshList();
    }, [refreshList]);

    const handleChangeSearch = (ev: any) => {
        setInputText(ev.target?.value.trim() || '');
    };


    const handleKeyUp = (ev: any) => {
        if (ev.code === 'Enter') {
            if (inputText) {
                setActivity(inputText);
                setInputText(''); // 입력창 초기화
            }
        }
    };

    const columns: ColumnsType<ActivityRecord> = [
        {
            title: '일시',
            dataIndex: 'date',
            key: 'date',
            width: 250,
        },
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
            width: 250,
        },
        {
            title: '활동내역',
            dataIndex: 'activity',
            key: 'activity',
        }
    ];

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <div className={css.activities}>
                <h2>관리 로그</h2>

                <div className={css.header}>
                    <div className={css.top}>
                        <div className={css.right}>
                            <TextField
                                className={css.search}
                                icon="search"
                                placeholder="ex. 활동내역"
                                value={inputText}
                                onKeyUp={handleKeyUp}
                                onChange={handleChangeSearch}
                            />
                            <Button icon="filter" onClick={() => setOpenFilter(true)} />
                            <Button icon="reset" priority="secondary" type="line" inline onClick={reset}>{'초기화'}</Button>
                        </div>
                    </div>

                </div>
                <Divider />
                <div style={{ overflowX: 'auto' }}>
                    <Table
                        columns={columns}
                        dataSource={activityList}
                        rowKey="date"
                        pagination={{
                            total: activityList.length
                        }}
                    />
                </div>
                <FilterPopup
                    open={isOpenFilter}
                    onClose={() => setOpenFilter(false)}
                    onApply={handleApplyFilter}
                />
            </div>
        </ConfigProvider>
    );
};

export default Activities;
